<template>
  <div>
    <template v-if="loader === true">
      <div class="animate-pulse">
        <div class="flex mb-2 px-4" v-for="item in 5" :key="item">
          <span class="block w-full mr-1 h-16 bg-stone-200 rounded-xl"></span>
          <span class="block w-full mx-1 h-16 bg-stone-200 rounded-xl"></span>
          <span class="block w-full mx-1 h-16 bg-stone-200 rounded-xl"></span>
          <span class="block w-full ml-1 h-16 bg-stone-200 rounded-xl"></span>
        </div>
      </div>
    </template>
    <v-date-picker
      v-else-if="loader === false && slotsStore.dates.length"
      color="#72E0D8"
      class="vcalendar w-full"
      :available-dates="slotsStore.dates"
      :locale="{
        id: 'ru',
        firstDayOfWeek: 2,
        masks: { weekdays: 'WW' },
      }"
      v-model="slotsStore.date"
      @dayclick="scrollTo('times', 'dialog-app', 50)"
      is-expanded
      title-position="left"
    />
    <template v-else-if="loader === false && !slotsStore.dates.length">
      <div class="min-h-[80vh] px-4">
        <EmptySlots></EmptySlots>
      </div>
    </template>
  </div>
</template>

<script>
import { scrollTo } from "../../libs/ui";
import { useSlots } from "../../store/slots";
import EmptySlots from "../Card/EmptySlots";

export default {
  components: { EmptySlots },
  data() {
    return {
      loader: null,
    };
  },
  setup() {
    const slotsStore = useSlots();

    return {
      slotsStore,
      scrollTo,
    };
  },

  async mounted() {
    this.loader = true;
    if (this.slotsStore.target !== "home") {
      await this.slotsStore.updateCalendar();
    }
    if (Object.keys(this.slotsStore.calendar).length) {
      let data = this.slotsStore.getNearDate(this.slotsStore.calendar);

      setTimeout(() => {
        // this.slotsStore.date = new Date(data);
        this.loader = false;
      }, 2000);
    } else {
      setTimeout(() => {
        this.loader = false;
      }, 2000);
    }
  },
  watch() {},

  methods: {},
};
</script>

<style>
.visit-calendar {
  font-family: "Circe";
}

.visit-calendar .vc-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 500;
  color: rgb(17, 24, 39);
}

.visit-calendar span.vc-day-content {
  font-size: 16px;
  line-height: 16px;
  @media (max-width: 450px) {
    height: 2.2em;

    width: 2.2em;
  }
}

.visit-calendar .vc-day .vc-highlight {
  @media (max-width: 450px) {
    height: 2.5em;
    width: 2.5em;
  }
}
</style>
