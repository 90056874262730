<template>
    <ClientOnly>
        <HomeStep1Map />
        <div class="mb-[100px]">
        <HomeStep1Address class="pt-2 px-4"  />
        <HomeStep1Debug class="pt-2 mt-4 px-4" v-if="uiStore.debugMode" />
        </div>

        <div class="mt-3" v-if="homeStore?.coords?.length">
            <HomeStep1Action class="pt-2 absolute bottom-2 w-full" />
        </div>
    </ClientOnly>
</template>
<script>
import { useUi } from "~/store/ui";
import { useHome } from "~/store/home";

export default {
    data() {
        return {
        };
    },
    setup() {
        const homeStore = useHome();
        return {
            homeStore,
            uiStore: useUi()
        };
    },
};
</script>
