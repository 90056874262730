<template>
    <HomeStep4Complite />
</template>

<script>
export default {
    data() {
        return {};
    },
    setup() {
        return {};
    }
};

</script>
