<template>
    <div>
        <template v-if="homeStore.loader === true">
            <div class="animate-pulse">
                <div class="flex mb-2" v-for="i in 5">
                    <span class="block w-full mr-1 h-12 bg-gray-200 rounded-xl" v-for="j in 6"></span>
                </div>
            </div>
        </template>
        <v-date-picker
            v-else-if="homeStore.loader === false && homeStore.dates.length"
            color="#D00273"
            class="vcalendar w-full vcalendar-red" :available-dates="homeStore.dates"
            :locale="{
                id: 'ru',
                firstDayOfWeek: 2,
                masks: { weekdays: 'WW' },
            }"
            v-model="homeStore.select.date"
            @dayclick="scrollTo('times', 'dialog-app', 50)"
            is-expanded
            title-position="left"
        />
        <template v-else-if="homeStore.loader === false && !homeStore.dates.length">
            <div class="min-h-[80vh] px-4">
                <EmptySlots></EmptySlots>
            </div>
        </template>
    </div>
</template>

<script>
import { scrollTo } from "../../libs/ui";
import EmptySlots from "./EmptySlots";
import { useHome } from "~/store/home";
import { useDocs } from "~/store/docs";

export default {
    components: { EmptySlots },
    data() {
        return {
            loader: null
        };
    },
    async setup() {
        const homeStore = useHome();
        const docStore = useDocs();
        if (docStore.listDocs.length === 0) {
            await docStore.fetchDocs();
        }

        return {
            docStore,
            homeStore,
            scrollTo
        };
    },

    async mounted() {

        this.homeStore.loader = true;
        await this.homeStore.createCalendar();

        setTimeout(() => {
            this.homeStore.loader = false;
        }, 1000);
    },
    watch() {
    },

    methods: {}
};
</script>

<style lang="scss">
.vcalendar-red.vcalendar {
    .vc-highlights .vc-highlight {
        background-color: #D00273 !important;
    }

    div.vc-highlights + span {
        color: white !important;
    }
}
</style>
