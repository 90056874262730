<template>
    <div class="relative w-full h-full flex flex-col justify-between">
        <!-- Фоновое изображение -->
        <div class="w-full">
            <nuxt-img
                src="/img/map-home-complite.png"
                class="w-full h-full object-contain"
                alt="Background map"
            />
            <div class="text-base text-center mt-[-120px] pb-[100px]">
                <div class="text-3xl pb-2">Спасибо!</div>
                <div class="text-xl">Вы оформили вызов педиатра на дом.</div>
            </div>
        </div>

        <div class="h-1/2 flex flex-col justify-around pb-16">
            <div class="flex justify-between flex-col items-center">
                <div class="opacity-50 mb-2">Адрес</div>
                <div class="text-xl">{{ homeStore.address }}</div>
            </div>

            <div class="flex justify-between flex-col items-center">
                <div class="opacity-50 mb-2">Врач приедет</div>
                <div class="text-xl">{{ createDateText(homeStore.select.date) }},
                    c {{
                        addMinutes(
                            createTimeText(homeStore.select.time),
                            homeStore.zone.delay
                        )
                    }}
                    до
                    {{
                        addMinutes(createTimeText(homeStore.select.time), homeStore.zone.time)
                    }}
                </div>
            </div>

            <div class="flex justify-between flex-col items-center">
                <div class="opacity-50 mb-2">Стоимость</div>
                <div class="text-xl">{{ numberWithSpaces(homeStore.zone.cost) }} р</div>
            </div>
        </div>

        <div class="pb-4 px-4">
            <UIBtnWhite :text="`${loader ? 'Отменяем...' : 'Отменить запись'}`" @click="cancelBook(loader)" class="mb-4"/>
            <UIBtnRed text="Вернуться на сайт" @click="homeStore.open = false" />
        </div>
    </div>
</template>

<script>
import { createDateText, createTimeText, addMinutes } from "~/libs/date";
import { useDocs } from "~/store/docs";
import { useClinics } from "~/store/clinics";
import { usePrices } from "~/store/price";
import { numberWithSpaces } from "~/libs/ui";
import { useHome } from "~/store/home";

export default {
    data() {
        return {
            loader: false
        };
    },
    setup() {
        const docsStore = useDocs();
        const clinicsStore = useClinics();
        const priceStore = usePrices();
        const homeStore = useHome();

        return {
            homeStore,
            priceStore,
            docsStore,
            clinicsStore,
            createDateText,
            createTimeText,
            numberWithSpaces,
            addMinutes
        };
    },
    computed: {
        docName() {
            const doc = this.docsStore.getDocById(this.homeStore.docId);
            return doc?.attributes?.lname + " " + doc?.attributes?.fname;
        }
    },
    methods: {
        async cancelBook(loader) {
            if (!loader) {
                this.loader = true;
                await this.homeStore.cancelBook();
                setTimeout(() => {
                    this.homeStore.open = false;
                    this.homeStore.complete = false;
                    this.homeStore.clearVisit();
                    this.loader = false;
                }, 3000);
            }
        },
        cach() {
            window.open(this.slotsStore.cachBookLink);
        }
    }
};
</script>
