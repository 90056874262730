<template>
    <div
        class="w-full cursor-pointer h-12 px-6 py-3 border border-neutral-100 rounded-3xl justify-center items-center gap-2 inline-flex">
        <div class="text-zinc-800 text-base font-normal leading-normal">
            {{ text }}
        </div>
    </div>
</template>

<script>
export default {
    props: ["text"],
}
</script>
