import { acceptHMRUpdate, defineStore } from "pinia";
import { useSchedule } from "~/store/app/schedule";
import { useRegion } from "~/store/app/region";
import { useGroups } from "~/store/app/priceGroup";
import { useDocs } from "~/store/docs";
import { usePatient } from "~/store/app/patient";
import { useClinics } from "~/store/clinics";
import { usePrices } from "~/store/price";
import { useSpecials } from "~/store/specials";
import { useNav } from "~/store/app/nav";
import { useEmail } from "~/store/email";
import { useStat } from "~/store/stat";
import { useSlots } from "~/store/slots";
import { createDateTextWithWeekday } from "~/libs/date";
import { useOrder } from "~/store/app/order";

export const useApp = defineStore("app", {
    state: () => {
        const config = useRuntimeConfig();

        return {
            mode: "app", // 'app' || 'order'
            apiBase: config.public.apiBase,
            appId: null,
            payLink: null,
            debug: false,

            open: false,
            type: null, //doc,special,service,home

            docId: null,
            specialId: null,
            serviceId: null,

            start: {
                docId: null,
                specialId: null,
                serviceId: null
            },

            search: {
                doc: null,
                special: null,
                service: null
            },

            clinicCalendar: null,

            clinicId: null,

            groupId: null,
            priceId: null,
            docMisId: null,


            where: null, //clinic,online
            cold: null, //true||false

            date: null,
            time: null,

            listReasons: [
                {
                    id: 0,
                    title: "по болезни (с признаками ОРВИ)",
                    subtitle: "*сыпь, температура, рвота, понос, сопли, кашель",
                    time: 60,
                    select: false,
                    infected: true
                },
                {
                    id: 1,
                    title: "по болезни (без признаков ОРВИ)",
                    subtitle: "",
                    time: 60,
                    select: false,
                    infected: false
                },
                {
                    id: 2,
                    title: "плановый прием",
                    subtitle: "",
                    time: 60,
                    select: false,
                    infected: false
                },
                {
                    id: 3,
                    title: "плановый прием + вакцинация",
                    subtitle: "",
                    time: 60,
                    select: false,
                    infected: false
                },
                {
                    id: 4,
                    title: "только вакцинация",
                    subtitle: "",
                    time: 30,
                    select: false,
                    infected: false
                },
                {
                    id: 5,
                    title: "оформление справки",
                    subtitle: "",
                    time: 30,
                    select: false,
                    infected: false
                }
            ],

            dataNearDate: []
        };
    },
    getters: {
        // 23.09 "только вакцинация" скрыть для всех регионов кроме Краснодар
        filterListReasons: (state) => {
            const regionStore = useRegion();
            const region = regionStore.regionId;
            return state.listReasons.filter((item) => {
                if (item.id === 4 && region !== "krasnodar") return false;
                return true;
            });
        },
        hotDocsList: (state) => (online = false) => {
            const docStore = useDocs();
            const patientStore = usePatient();
            const regionStore = useRegion();

            const age = patientStore.patient.age;
            const listDoctorsRegion = regionStore.listDoctorsRegion();
            const listFilteredByAge = docStore.filterByAge(listDoctorsRegion, age);

            if (online) {
                return docStore.filterByOnline(listFilteredByAge, true);
            }
            return listFilteredByAge.length ? listFilteredByAge : [];
        },
        hotDoc() {
            const docStore = useDocs();
            if (this.docMisId) {
                return docStore.getDocByMisId(this.docMisId);
            }
            return docStore.getDocById(this.docId);
        },
        hotSpecial() {
            const specialStore = useSpecials();
            const special = specialStore.getSpecialById(this.specialId);
            return {
                title: special.attributes.title,
                ...special
            };
        },
        hotService() {
            const priceStore = usePrices();
            const price = priceStore.getPriceById(this.serviceId);
            return {
                title: price.attributes.title,
                ...price
            };
        },
        hotClinic() {
            const clinicStore = useClinics();
            return clinicStore.getClinicById(this.clinicId);
        },
        hotPrice() {
            const priceStore = usePrices();
            return priceStore.getPriceById(this.priceId);
        },
        hotListGroups(state) {
            const groupStore = useGroups();
            const priceStore = usePrices();
            const specialStore = useSpecials();
            let listPriceIds = [];
            let listDocs = [];

            switch (state.type) {
                case "doc":
                    listDocs = [state.docId];
                    listPriceIds = groupStore.getPricesByDocsIds(listDocs);
                    break;
                case "special":
                    if (state.specialId !== null) {
                        const special = specialStore.getSpecialById(state.specialId);
                        listDocs = special.attributes.docs.data.map(doc => doc.id);
                        listPriceIds = special.attributes.prices.data.map(price => price.id);
                    }
                    break;
                case "service":
                    if (state.serviceId !== null) {
                        const service = priceStore.getPriceById(state.serviceId);
                        listDocs = service.attributes.docs.data.map(doc => doc.id);
                        listPriceIds = [state.serviceId];
                    }
                    break;
            }

            if (listDocs.length) {
                const filterListPricesIds = priceStore.filterByOnline(listPriceIds, this.where === "online");
                const listGroupsIds = groupStore.getGroupsByPriceIds(filterListPricesIds);
                const listGroups = [];
                if (listGroupsIds.length) {
                    listGroupsIds.forEach((id) => {
                        listGroups.push(groupStore.getGroupById(id));
                    });
                }
                return listGroups;
            }
            return [];
        },
        hotListPrices(state) {
            const groupStore = useGroups();
            const priceStore = usePrices();
            const specialStore = useSpecials();
            let listDocs = [];
            let listPrices = [];

            switch (state.type) {
                case "doc":
                    listDocs.push(state.docId);
                    listPrices = groupStore.getPricesByDocsIds(listDocs);
                    break;
                case "special":
                    if (state.specialId !== null) {
                        const special = specialStore.getSpecialById(state.specialId);
                        listPrices = special.attributes.prices.data.map(price => price.id);
                    }
                    break;
                case "service":
                    if (state.serviceId !== null) {
                        listPrices = [state.serviceId];
                        return listPrices;
                    }
                    break;
                case "home":
                    break;
            }

            if (state.groupId === null) return [];
            const group = groupStore.getGroupById(state.groupId);
            const listPricesIds = priceStore.filterByOnline(group.attributes.prices.data.map(price => price.id), state.where === "online");
            return listPrices.filter(priceId => listPricesIds.indexOf(priceId) !== -1);
        },
        hotCost() {
            if (this.priceId === null) return;
            const priceStore = usePrices();
            const price = priceStore.getPriceById(this.priceId);
            let result;

            price.attributes.price.forEach((item) => {
                    if (item.clinic.data.attributes.clinicId === this.clinicId) {
                        result = item.Price;
                    }
                }
            );
            return result;
        },
        hotDocPrice: (state) => (docId, clinicId = null, hotListPrices = []) => {
            const priceStore = usePrices();
            const docStore = useDocs();
            const regionStore = useRegion();
            const doc = docStore.getDocById(docId);
            let listClinics = [];
            if (clinicId)
                listClinics = [clinicId];
            else
                listClinics = regionStore.listClinicsRegion();

            let min = null;
            let labelmin = "";

            const updateMinPrice = (price) => {
                if (min !== null && price !== min)
                    labelmin = "от ";
                if (min > price || min === null) {
                    min = price;
                }
            };

            doc.attributes.book.forEach(book => {
                const clinicId = book?.clinic?.data?.attributes?.clinicId;

                book?.pricetime?.forEach(async pricetime => {
                    const priceId = pricetime.price.data.id;

                    if ((hotListPrices.length === 0 || hotListPrices.indexOf(priceId) !== -1) && listClinics.indexOf(clinicId) !== -1) {
                        const price = priceStore.getPriceById(priceId);

                        price.attributes.price.forEach((item) => {
                            if (item.clinic.data.attributes.clinicId === clinicId && item.Price !== null) {
                                updateMinPrice(item.Price);
                            } else {
                                if (state.debug)
                                    console.error("Стоимость не найдена для позиции прайса в клинике для доктора: ", priceId, clinicId, doc.attributes.lname);
                            }
                        });
                    }
                });
            });

            if (min !== null) {
                return labelmin + min;
            }
        },
        hotServicesList: (state) => {
            const listDocs = state.hotDocsList();
            const docStore = useDocs();
            const result = new Set();
            for (let docId of listDocs) {
                const doc = docStore.getDocById(docId);
                if (doc.attributes?.book?.length) {
                    for (let combo of doc.attributes.book) {
                        for (let comboPriceTime of combo?.pricetime) {
                            if (!comboPriceTime?.price?.data?.id) {
                                console.log("пустой прайс в book у врача: ", doc);
                                continue;
                            }
                            result.add(comboPriceTime.price.data.id);
                        }
                    }
                }
            }
            return Array.from(result);
        },
        hotDateTime: (state) => {
            if (!state.date) return;
            let date = new Date(state.date);
            let year = date.getFullYear();
            let month = (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1); // Месяцы начинаются с 0
            let day = (date.getDate() < 10 ? "0" : "") + date.getDate();
            let formattedDate = `${year}-${month}-${day}`;

            return `${formattedDate} ${state.time}:00`;
        },
        isPediatrist: (state) => {
            switch (state.type) {
                case "doc":
                    const docStore = useDocs();
                    const doc = docStore.getDocById(state.docId);
                    return doc.attributes?.specials?.data.findIndex(special => special.id === 2) !== -1;
                case "special":
                    return state.specialId === 2;
            }
        },
        reasonPediatrist: (state) => {
            if (state.isPediatrist) {
                if (["special", "doc"].includes(state.type) && state.groupId === 1)
                    return state.listReasons.find(reason => reason.select)?.title;
            }
            return null;
        },
        formatReason: (state) => {
            const patientStore = usePatient();
            const reason = patientStore.patient.reason;
            const promo = patientStore.patient.promo;

            if (state.priceId === null) return;
            const priceStore = usePrices();
            const price = priceStore.getPriceById(state.priceId);
            const aliasPrice = price.attributes.title;

            let target = "Запись ";
            switch (state.type) {
                case "doc":
                    target += "по врачу";
                    break;
                case "special":
                    target += "по специализации";
                    break;
                case "service":
                    target += "по услуге";
                    break;
            }

            const reasonPediatrist = state.reasonPediatrist;

            return `${reason}${promo ? ` | ПРОМО: ${promo}` : ""} | ${aliasPrice} | ${target} ${reasonPediatrist ? `| Причина: ${reasonPediatrist}` : ""}`;
        },
        dataFilter: (state) => (type) => {
            let list, key, search;

            switch (type) {
                case "doc":
                    list = state.hotDocsList().map(docId => {
                        const doc = useDocs().getDocById(docId);
                        return { ...doc, title: doc.name };
                    });
                    key = "doc";
                    break;
                case "special":
                    const specialStore = useSpecials();

                    list = specialStore.listSpecials
                        .sort((a, b) => a.attributes.title.localeCompare(b.attributes.title))
                        .filter(special => {
                            if (special?.attributes?.docs.data.findIndex(doc => state.hotDocsList().includes(doc.id)) !== -1) {
                                // console.log(special.attributes.title, special?.attributes?.docs.data.findIndex(doc => {
                                //     if (state.hotDocsList().includes(doc.id)) {
                                //         console.log(doc.id)
                                //         return true;
                                //     }
                                // }));
                                return true;
                            }
                            return false;
                        })
                        .map(special => {
                            return { ...special, title: special.attributes.title };
                        });
                    key = "special";
                    break;
                case "service":
                    const priceStore = usePrices();
                    list = state.hotServicesList.map(service => priceStore.getPriceById(service)).filter(service => !service.attributes.hideInApp).sort((a, b) => a.attributes.title.localeCompare(b.attributes.title)).map(service => {
                        return { ...service, title: service.attributes.title };
                    });
                    key = "service";
                    break;
            }

            const filterList = item => {
                const text = item.title.trim().toLowerCase();
                return text.includes(state.search[key].toLowerCase().trim());
            };

            return state.search[key] === null || state.search[key].length === 0 ? list : list.filter(filterList);
        },
        hasStart: (state) => {
            if (state.start.docId) return "doc";
            if (state.start.specialId) return "special";
            if (state.start.serviceId) return "service";
            return false;
        },
        infoStat: (state) => {
            const an_ga4_cid = getan_ga4_cid();
            const an_ga4_sessid = getan_ga4_sessid();
            const an_ym_cid = getan_ym_cid();
            const an_ct_cid = getan_ct_cid();

            function getan_ga4_sessid() {
                const match = document.cookie.match(/_ga_JFXW4PSWG6=(.+?)(;|$)/);

                function extractTimestamp(str) {
                    const pattern = /GS\d\.\d\.(\d+)\./;
                    const match = str.match(pattern);
                    return match ? match[1] : null;
                }

                if (!match || !match?.[1]) return "";
                const str = match[1];
                const timestamp = extractTimestamp(str);

                return (timestamp) ? timestamp : "";
            }

            function getan_ct_cid() {
                if (window && window.ct && window.ct("calltracking_params")?.length) {
                    const calltracking_params = window.ct("calltracking_params");
                    if (calltracking_params[0]["ctClientId"]) {
                        return calltracking_params[0]["ctClientId"];
                    }
                }
                return "";
            }

            function getan_ga4_cid() {
                const match = document.cookie.match(/_ga=(.+?)(;|$)/);
                return (match) ? match[1] : "";
            }

            function getan_ym_cid() {
                var match = document.cookie.match("(?:^|;)\\s*_ym_uid=([^;]*)");
                return (match) ? decodeURIComponent(match[1]) : "";
            }

            return {
                an_ga4_cid,
                an_ga4_sessid,
                an_ym_cid,
                an_ct_cid
            };
        }
    },
    actions: {
        async createApp() {
            function reformatDate(date) {
                let dateParts = date.split(".");
                return dateParts.reverse().join("-");
            }

            function calculateCach(priceId, clinicId) {
                const priceStore = usePrices();
                const price = priceStore.getPriceById(priceId);
                return price.attributes.price.find(item => item.clinic.data.attributes.clinicId === clinicId).cach;
            }

            function constructUrl(apiBase, clinicId, docMisId, patient, hotDateTime, reason, duration, cach, infoStat) {
                return `${apiBase}/api/mis/book?clinicId=${clinicId}&doctorId=${docMisId}&name=${patient.name}&phone=${patient.phone}&birthDay=${reformatDate(patient.bday)}T00:00:00.000Z&time=${hotDateTime}&comment=${reason}&duration=${duration}&now=${new Date()}&cach=${cach}&an_ga4_cid=${infoStat?.an_ga4_cid ? infoStat?.an_ga4_cid : ""}&an_ga4_sessid=${infoStat?.an_ga4_sessid ? infoStat?.an_ga4_sessid : ""}&an_ym_cid=${infoStat?.an_ym_cid ? infoStat?.an_ym_cid : ""}&an_ct_cid=${infoStat?.an_ct_cid ? infoStat?.an_ct_cid : ""}`;
            }

            async function handleError(error) {
                await this.createAppMailTech(error.toString());
                console.error(error);
            }

            try {
                const patientStore = usePatient();
                const statStore = useStat();

                const requestOptions = {
                    method: "GET",
                    redirect: "follow"
                };

                const url = constructUrl(
                    this.apiBase,
                    this.clinicId,
                    this.docMisId,
                    patientStore.patient,
                    this.hotDateTime,
                    this.formatReason,
                    this.getDuration(this.docMisId, this.priceId, this.clinicId),
                    calculateCach(this.priceId, this.clinicId),
                    this.infoStat
                );
                const response = await fetch(url, requestOptions);

                this.appId = await response.text();

                if (this.appId.length === 0) {
                    statStore.goal("error");
                } else {
                    statStore.goal("success");
                    statStore.callTouch({
                        fio: patientStore.patient.name,
                        phone: patientStore.patient.phone,
                        email: patientStore.patient.email,
                        subject: "Запись на прием",
                        tag: "book",
                        requestUrl: window.location.href
                    });
                }

                // if (doc.attributes.cach) {
                //     const cachResponse = await fetch(
                //         `${this.apiBase}/api/mis/cach?clinicId=${this.clinicId}&bookId=${this.appId}`,
                //         requestOptions
                //     );
                //
                //     const payLink = await cachResponse.json();
                //     this.payLink = payLink["_text"].replace('&amp;', '&');

                return this.appId;
            } catch (error) {
                await handleError.call(this, error);
            }
        },
        async createAppMail(status) {
            try {
                const patientStore = usePatient();
                const mailStore = useEmail();
                const webvisor = () => {
                    const match = document.cookie.match("(?:^|;)\\s*_ym_uid=([^;]*)");
                    return (match) ? decodeURIComponent(match[1]) : "";
                };
                const payload = {
                    bookId: this.appId,
                    clinicId: this.clinicId,
                    fio: patientStore.patient.name,
                    phone: patientStore.patient.phone,
                    birthday: patientStore.patient.bday,
                    comment: patientStore.patient.reason,
                    clinic: this.hotClinic.attributes.title,
                    doctor: this.hotDoc.attributes.lname + " " + this.hotDoc.attributes.fname,
                    time: this.hotDateTime,
                    webvisor: webvisor(),
                    duration: this.getDuration(this.docMisId, this.priceId, this.clinicId),
                    promo: patientStore.patient.promo
                };
                switch (this.type) {
                    case "doc":
                        payload.target = "по врачу";
                        break;
                    case "special":
                        const special = useSpecials().getSpecialById(this.specialId);
                        payload.target = `по специализации: ${special.attributes.title.toLowerCase()}`;
                        break;
                    case "service":
                        const service = usePrices().getPriceById(this.serviceId);
                        payload.target = `по услуге: ${service.attributes.title.toLowerCase()}`;
                        break;
                }

                await mailStore.send(status ? "app" : "error", payload);
            } catch (error) {
                console.log(error);
            }
        },
        async createAppMailTech(error) {
            const mailStore = useEmail();
            await mailStore.send("tech", { error });
        },
        async cancelApp() {
            const requestOptions = {
                method: "GET",
                redirect: "follow"
            };

            await fetch(
                `${this.apiBase}/api/mis/cancelBook?clinicId=${this.clinicId}&bookId=${this.appId}`,
                requestOptions
            )
                .then((response) => response.text())
                .then((result) => result)
                .catch((error) => console.log("error", error));

            this.appId = null;
        },
        async createCalendar() {
            const patientStore = usePatient();
            const scheduleStore = useSchedule();
            const regionStore = useRegion();
            const docStore = useDocs();
            const specialStore = useSpecials();
            const priceStore = usePrices();
            const listPricesIds = this.hotListPrices;
            let listClinics = regionStore.listClinicsRegion();

            if (this.clinicCalendar !== null) {
                listClinics = listClinics.filter(clinic => clinic === this.clinicCalendar);
            }

            let listDocs = [];
            let listCategories = [];
            scheduleStore.schedule = {};

            //1 - в клинике
            //2 - в клинике и онлайн
            //3 - онлайн
            //4 - выезд на дом
            //0 - не указано
            switch (this.where) {
                case "clinic":
                    listCategories = [1, 2];
                    break;
                case "online":
                    listCategories = [2, 3];
                    break;
                case "home":
                    listCategories = [4];
                    break;
            }

            switch (this.type) {
                case "doc":
                    listDocs.push(this.docId);
                    break;
                case "special":
                    if (this.specialId !== null) {
                        const special = specialStore.getSpecialById(this.specialId);
                        listDocs = special.attributes.docs.data.map(doc => doc.id);
                    }
                    break;
                case "service":
                    if (this.serviceId !== null) {
                        const service = priceStore.getPriceById(this.serviceId);
                        listDocs = service.attributes.docs.data.map(doc => doc.id);
                    }
                    break;
                case "home":
                    break;
            }

            const listScheduleGet = [];
            docStore.filterByAge(listDocs, patientStore.patient.age).forEach(docId => {
                const doc = docStore.getDocById(docId);
                if (!doc && this.debug)
                    console.error(`docId: ${docId} - unpublish`);
                else
                    doc?.attributes?.book.forEach(book => {
                        const clinicId = book?.clinic?.data?.attributes?.clinicId;
                        if (clinicId === undefined && this.debug)
                            console.error(`Не хватает данных у доктора(${doc.attributes.lname}) в book: clinicId`);

                        book?.pricetime?.forEach(async pricetime => {
                            const priceId = pricetime?.price?.data?.id;
                            if (priceId === undefined && this.debug)
                                console.error(`Не хватает данных у доктора(${doc.attributes.lname}) в book: priceId`);

                            const time = pricetime.time;
                            if (listPricesIds.indexOf(priceId) !== -1 && listClinics.indexOf(clinicId) !== -1) {
                                listScheduleGet.push(scheduleStore.get(docId, clinicId, time, listCategories, priceId));
                                // await scheduleStore.get(docId, clinicId, time, listCategories, priceId);
                            }
                        });
                    });
            });

            await Promise.all(listScheduleGet)
                .then(() => console.log("All scheduleStore.get calls completed"))
                .catch(error => console.error("Some scheduleStore.get calls failed", error));
        },
        openApp(type, target = null, typeVisit = "visit") {
            const slotsStore = useSlots();
            this.unsetStart();

            if (target !== "home") {
                switch (type) {
                    case "price":
                        this.serviceId = target;
                        this.start.serviceId = target;
                        this.type = "service";
                        break;
                    case "special":
                        this.start.specialId = target;
                        this.specialId = target;
                        this.type = type;
                        break;
                    case "doc":
                        this.start.docId = target;
                        this.docId = target;
                        this.type = type;
                        break;
                }

                this.open = true;
            } else {
                slotsStore.openDialog(type, target);
            }
        },
        openOrder(comment = null) {
            this.open = true;
            this.mode = "order";

            if (comment !== null) {
                useOrder().comment = comment;
            }
        },
        unsetStart() {
            this.start.serviceId = null;
            this.start.specialId = null;
            this.start.docId = null;
            this.type = null;
        },
        setReason(reasonId) {
            this.listReasons.forEach((item) => {
                item.select = +item.id === +reasonId;
            });
        },
        clearApp(step) {
            switch (step) {
                case 3:
                    this.search.doc = null;
                    this.search.special = null;
                    this.search.service = null;
                    this.where = null;
                    this.groupId = null;
                    this.date = null;
                    this.time = null;
                    break;
                case 4:
                    this.clinicId = null;
                    this.date = null;
                    this.time = null;
                    this.clinicCalendar = null;
                    break;
                case 7:
                    this.docId = null;
                    this.specialId = null;
                    this.serviceId = null;
                    this.clinicId = null;
                    this.priceId = null;
                    break;
                case 0:
                    this.mode = "app";
                    this.open = false;
                    this.appId = null;
                    this.payLink = null;
                    this.type = null;
                    this.docId = null;
                    this.specialId = null;
                    this.serviceId = null;
                    this.clinicId = null;
                    this.groupId = null;
                    this.priceId = null;
                    this.docMisId = null;
                    this.where = null;
                    this.cold = null;
                    this.date = null;
                    this.time = null;
                    const patientStore = usePatient();
                    patientStore.patient.age = null;

            }
        },
        getDuration(docId, priceId, clinicId) {
            const docStore = useDocs();
            const doc = docStore.getDocByMisId(docId);
            let duration = 0;
            doc.attributes.book.forEach(book => {
                if (book.clinic.data.attributes.clinicId === clinicId) {
                    book.pricetime.forEach(pricetime => {
                        if (pricetime.price.data.id === priceId) {
                            duration = pricetime.time;
                        }
                    });
                }
            });
            return duration;
        },
        closeApp() {
            this.clearApp(0);
        },
        setDebug(debug = true) {
            if (debug) {
                const patientStore = usePatient();
                patientStore.setDefault();
            }
            this.debug = debug;
        },
        async getDocNearDate(listDocs) {
            const docStore = useDocs();
            const priceStore = usePrices();
            const scheduleStore = useSchedule();

            let minPrice = {};

            if (!priceStore.listPrices.length) {
                await priceStore.fetchPrices();
            }

            if (!docStore.listDocs.length) {
                await docStore.fetchDocs();
            }

            for (let docId of listDocs) {
                const doc = docStore.getDocById(docId);

                if (!doc?.attributes?.book) {
                    continue;
                }

                for (const data of doc.attributes.book) {
                    const clinicId = data.clinic?.data?.attributes?.clinicId;
                    if (clinicId === undefined) {
                        continue;
                    }

                    for (const pricetime of data.pricetime) {
                        const slots = await scheduleStore.get(doc.id, clinicId, pricetime.time);
                        const priceId = pricetime.price.data.id;
                        const price = priceStore.priceInClinic(priceId, clinicId);
                        if (!price) {
                            continue;
                        }

                        if (!minPrice[clinicId]) {
                            minPrice[clinicId] = {
                                price: price,
                                nearestDate: slots ? slots[0]?.from?._text : null,
                                near: slots ? "c " + createDateTextWithWeekday(slots[0]?.from?._text) : "лист ожидания",
                                count: 1
                            };
                        } else {
                            if (price < minPrice[clinicId].price) {
                                minPrice[clinicId].price = price;
                                minPrice[clinicId].count += 1;
                            }
                            if (slots && (!minPrice[clinicId].nearestDate || new Date(slots[0]?.from?._text) < new Date(minPrice[clinicId].nearestDate))) {
                                minPrice[clinicId].nearestDate = slots[0]?.from?._text;
                                minPrice[clinicId].near = slots ? "c " + createDateTextWithWeekday(slots[0]?.from?._text) : "лист ожидания";
                            }
                        }
                    }
                }
            }

            //пойди по всем clinicId в minPrice и найди минимальную цену
            let minPriceValue = null;
            let minDateValue = null;
            for (let clinicId in minPrice) {
                if (minPriceValue === null || minPrice[clinicId].price < minPriceValue) {
                    minPriceValue = minPrice[clinicId].price;
                }
                if (minDateValue === null || (new Date(minPrice[clinicId].nearestDate) < new Date(minDateValue) && minPrice[clinicId].nearestDate)) {
                    minDateValue = minPrice[clinicId].nearestDate;
                }
            }

            return {
                ...minPrice,
                price: minPriceValue,
                near: minDateValue ? "c " + createDateTextWithWeekday(minDateValue) : "лист ожидания"
            };
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useApp, import.meta.hot));
}
