<template>
    <template v-if="loader">
        <AppLoader text="Загрузка графика" />
    </template>
    <template v-else>
        <div class="wrap_calendar">
            <AppStep5Calendar class="mb-8" />
            <AppStep5Times v-if="appStore.date" />
        </div>
    </template>
</template>
<script>
import { useApp } from "~/store/app/app";
import { scrollTo } from "~/libs/ui";

export default {
    data() {
        return {
            loader: false
        };
    },
    async setup() {
        const appStore = useApp();

        return {
            appStore
        };
    },
    async mounted() {
        try {
            this.loader = true;
            this.appStore.clearApp(4);
            await this.appStore.createCalendar();
            this.loader = false;
        } catch (e) {
            console.log(e);
        }
    },
    watch: {
        "appStore.date": (val) => {
            scrollTo("times", "wrap_calendar");
        }
    }
};
</script>
