<template>
    <div v-if="homeStore?.zone?.title" class="mx-4 bg-teal-50 p-5 border">
        <div @click="openLinkAdmin()" class="cursor-pointer underline">{{ homeStore.zone.title }}</div>
        <br />

        <b>Стоимость:</b>
        {{
            homeStore?.zone?.cost
                ? numberWithSpaces(homeStore.zone.cost) + " руб."
                : "НЕТ СТОИМОСТИ"
        }}
        <br />
        <b>Слот:</b> {{ homeStore.zone.time }} мин.
        <br />
        <b>Задержка:</b> {{ homeStore.zone.delay }} мин.
        <br />
        <br />
        <b>Врачи:</b>
        <template v-if="homeStore?.zone?.docs?.data">
            <div v-for="doc of homeStore.zone.docs.data">
                {{ doc.attributes.fname }}
                {{ doc.attributes.lname }}
            </div>
        </template>

        <br />
        <br />
        <UIBtnGreen text="Открыть Яндекс.Карты" @click="openLinkMap()" />
    </div>
</template>

<script>
import { useHome } from "~/store/home";
import { useSlots } from "~/store/slots";
import { numberWithSpaces } from "~/libs/ui";

export default {
    setup() {
        const homeStore = useHome();

        return {
            homeStore,
            numberWithSpaces
        };
    },
    methods: {
        openLinkMap() {
            const yandexMapsLink = `https://yandex.ru/maps/?text=${encodeURIComponent(
                this.homeStore.address
            )}`;
            window.open(yandexMapsLink, "_blank");
        },
        openLinkAdmin() {
            window.open(this.zoneLink, "_blank");
        }
    },
    computed: {
        zoneLink() {
            return `https://admin.fomin-kids.ru/admin/content-manager/collectionType/api::zone.zone/${this.homeStore.zone.id}`;
        }
    }
};
</script>
