<template>
    <div
        class="mt-6 text-xl"
        v-if="homeStore.select.date"
    >
        <span class="whitespace-nowrap">{{ createDateText(homeStore.select.date) }}</span>
    </div>
    <div
        class="mt-2"
        role="radiogroup"
        aria-label="Выберите время"
    >
        <div
            class="grid grid-cols-4 gap-x-2 gap-y-1.5 text-center py-4"
            :class="gridClass"
        >
            <button
                v-for="slot in homeStore.timeList"
                :key="slot.name"
                :class="getSlotClass(slot)"
                @click="handleSlotSelection(slot.value)"
                tabindex="0"
                role="radio"
                :aria-checked="slot.value === homeStore.select.time"
                type="button"
            >
                <template v-if="!uiStore.debugMode">
                    {{ addMinutes(slot.name, homeStore.zone.delay) }} - {{ addMinutes(slot.name, homeStore.zone.time) }}
                </template>
                <template v-else>
                    <div>
                        <div v-for="docId of slot.listDocId" class="normal-case">
                            {{ docsStore.getDocByMisId(docId).attributes.lname}}
                        </div>
                        <br>

                        {{ addMinutes(slot.name, homeStore.zone.delay) }} - {{ addMinutes(slot.name, homeStore.zone.time) }}<br>
                        <small>{{ slot.name }} - {{ addMinutes(slot.name, homeStore.zone.time) }}</small>
                    </div>
                </template>
            </button>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
import { createDateText, addMinutes } from "../../libs/date";
import { useHome } from "../../store/home";
import { useUi } from "../../store/ui";
import { scrollTo } from "../../libs/ui";
import { useDocs } from "~/store/docs";

export default {
    setup() {
        const homeStore = useHome();
        const uiStore = useUi();
        const docsStore = useDocs();

        const handleScrollToContacts = () => scrollTo("contacts", "contacts");

        const handleSlotSelection = (value) => {
            homeStore.select.time = value;
            homeStore.nav.currentStep = 3;
        };

        const gridClass = computed(() => "xl:gap-2 sm:grid-cols-2");

        const getSlotClass = (slot) => [
            "cursor-pointer focus:outline-none",
            slot.value === homeStore.select.time
                ? "bg-[#D00273] border-transparent text-white"
                : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
            !uiStore.debugMode ? "rounded-full" : "",
            "border py-2 px-2 flex items-center justify-center text-base font-medium uppercase sm:flex-1 hover:border-[#D00273] text-sm"
        ];

        return {
            docsStore,
            uiStore,
            homeStore,
            createDateText,
            addMinutes,
            handleScrollToContacts,
            handleSlotSelection,
            gridClass,
            getSlotClass
        };
    }
};
</script>
