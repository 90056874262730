<template>
  <div class="fixed-marker" :class="{ 'is-active': active }">
    <div class="fixed-marker__pin">
      <div class="fixed-marker__circle">

          <div v-if="!loading && homeStore?.zone?.cost" class="fixed-marker__price">
            <div class="font-medium text-white px-2">
                {{ numberWithSpaces(homeStore.zone.cost) }} Р
            </div>
          </div>

        <div v-if="loading" role="status" class="spinner-container">
          <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"/>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
          </svg>
        </div>
      </div>
      <div class="fixed-marker__leg"/>
      <div class="fixed-marker__shadow"/>
    </div>
  </div>
</template>

<script setup>
import { numberWithSpaces } from "../../../../libs/ui";
import { useHome } from "../../../../store/home";

const homeStore = useHome();

defineProps({
  active: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  }
})

</script>

<style lang="scss" scoped>
.fixed-marker {
  margin-top: -18px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  pointer-events: none;

  &__pin {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(0);
    transition: transform 0.3s ease;
  }

  &__circle {
    min-width: 36px;
    height: 36px;
    border-radius: 1000px;
    background: #D00273;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -5px;
    padding: 5px;
  }

  &__leg {
    width: 2px;
    height: 20px;
    background: #D00273;
    transition: all 0.2s ease-out;
    transform-origin: top;
    margin-bottom: 12px;
  }

  &__shadow {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(38, 44, 44, 0.3);
    opacity: 0;
    transform: scale(0);
    transition: all 0.3s ease;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) scale(0);
  }

  &.is-active {
      //margin-top: -20px;

    .fixed-marker__pin {
      transform: translateY(-5px);
    }

    .fixed-marker__shadow {
      opacity: 1;
      transform: translateX(-50%) scale(1);
    }
  }
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
