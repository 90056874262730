<template>
    <div class="flex flex-col justify-between pb-4 h-full px-4">
        <HomeStep3Form ref="formRef" />

        <UIBtnRed @click="handleSubmit" text="Записаться" v-if="!isLoading" />
        <UIBtnWhite text="Отправка..." v-else />
    </div>
</template>

<script>
import { useHome } from "~/store/home";
import HomeStep3Form from "./Form.vue";

export default {
    name: "HomeStep3",
    components: {
        HomeStep3Form
    },
    data() {
        return {
            homeStore: useHome(),
            formRef: null,
            isLoading: false
        };
    },
    methods: {
        async handleSubmit() {
            const isValid = this.$refs.formRef.validate();
            if (isValid) {
                this.isLoading = true;
                await this.homeStore.createHome();
                this.homeStore.nav.currentStep = 4;
            }
            // Ошибки будут отображены внутри формы
        }
    }
};
</script>
