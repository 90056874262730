<template>
  <div class="relative w-full h-full flex flex-col justify-between">
    <!-- Фоновое изображение -->
    <div class="absolute inset-0 z-0 w-full">
      <nuxt-img
        src="/img/map-home.png"
        class="w-full h-full md:object-contain object-cover"
        alt="Background map"
      />
    </div>

    <!-- Контент поверх фона -->
    <div class="relative z-10 flex flex-col justify-between h-full">
      <!-- Верхний текст -->
      <div class="text-base pt-4 px-4">
          Врач подбирается системой автоматически. К вам приедет самый быстрый ⚡
      </div>

      <!-- Кнопка внизу -->
      <div class="pb-4 px-4">
        <UIBtnRed text="Вызвать педиатра" @click="handleNextStep"/>
      </div>
    </div>
  </div>
</template>

<script>
import { useHome } from "~/store/home";

export default {
  name: 'StepZero',
  setup() {
    const homeStore = useHome();
    return {
      homeStore
    }
  },
  methods: {
    handleNextStep() {
        this.homeStore.nav.currentStep = 1;
    }
  }
}
</script>
