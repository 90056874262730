<template>
    <div class="px-2">
        <div v-if="homeStore.zone !== null && !Object.values(homeStore.zone).length">
            <div class="px-4">
                <p class="font-bold text-base">
                    К сожалению, мы пока не сможем приехать по этому адресу;(
                </p>
                <p>
                    Пожалуйста, оставьте заявку - так мы быстрее включим ваш адрес в зону выездов и пришлем вам
                    оповещение
                </p>

                <div
                    class="w-full mt-2 flex items-center justify-center py-4 border border-pink-600 rounded-full shadow-sm text-sm uppercase font-medium text-pink-600 border-pink-600 bg-white hover:bg-pink-700 hover:text-white cursor-pointer"
                    @click="openOrder();"
                >
                    Оставить заявку
                </div>
            </div>
        </div>
        <div
            v-else
            class="flex-shrink-0 px-2 mt-8 pb-2"
        >
            <div class="flex flex-wrap items-center">
                <UIBtnRed
                    v-if="homeStore.zone"
                    text="Оформить вызов"
                    @click="goNext"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { useHome } from "~/store/home";
import { numberWithSpaces } from "~/libs/ui";
import { scrollTo } from "~/libs/ui";
import { useApp } from "~/store/app/app";

export default {
    setup() {
        const homeStore = useHome();
        const appStore = useApp();

        return {
            homeStore,
            appStore,
            numberWithSpaces,
            scrollTo
        };
    },
    methods: {
        async goNext() {
            this.homeStore.select.time = null;
            this.homeStore.select.date = null;
            this.homeStore.nextStep();
        },
        openOrder() {
            const comment = `Адрес вне зоны выезда: ${this.homeStore.address}`;
            this.appStore.openOrder(comment);
        }
    }
};
</script>
