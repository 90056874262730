<template>
    <div class="flex items-center home bg-teal-50 justify-center" id="map">
        <div v-if="showMap" class="w-full">
            <ClientOnly>
                <yandex-map
                    :coords="coords"
                    :zoom="zoom"
                    :state="state"
                    :options="options"
                    :controls="controls"
                    :behaviors="behaviors"
                    @boundschange="onBoundsChange"
                    @actionend="onMapActionEnd"
                >
                    <ymap-marker
                        v-if="hasCoords"
                        :coords="coords"
                        marker-id="marker"
                        :options="markerOptions"
                    />
                    <ymap-marker
                        v-for="(polygon, idx) of listPolygon"
                        :key="`polygon-${idx}`"
                        :coords="polygon.coords"
                        :marker-id="`polygon-${idx}`"
                        marker-type="Polygon"
                        :marker-fill="getPolygonFill(polygon)"
                        :marker-stroke="getPolygonStroke(polygon)"
                    />
                </yandex-map>
            </ClientOnly>
        </div>
        <div v-else>Загрузка карты...</div>
    </div>
</template>

<script>
import { useHome } from "~/store/home";
import { useClinics } from "~/store/clinics";
import { useUi } from "~/store/ui";

export default {
    data() {
        return {
            listPolygon: [],
            showMap: false,
            state: {
                controls: []
            },
            options: {
                suppressMapOpenBlock: true
            },
            controls: ["zoomControl"],
            behaviors: ["drag", "scrollZoom"],
            bounds: [],
            rectangleBounds: [],
            coords: [],
            zoom: 9,
            ymaps: null,
            markerOptions: {
                preset: 'islands#redDotIcon',
                iconColor: '#D3016E',
                iconLayout: 'default#image',
                iconImageOffset: [-12, -12],
                draggable: false
            }
        };
    },

    computed: {
        hasCoords() {
            return this.homeStore?.coords?.length > 0;
        },
        isDebugMode() {
            return this.uiStore.debugMode;
        },
        getPolygonFill() {
            return (polygon) => ({
                color: polygon.color,
                opacity: polygon.title === "bounds" ? 0.05 : 0.5
            });
        },
        getPolygonStroke() {
            return (polygon) => ({
                color: polygon.color,
                width: polygon.title === "bounds" ? 2 : 1,
                opacity: 0.5
            });
        }
    },

    setup() {
        const homeStore = useHome();
        const clinicStore = useClinics();
        const uiStore = useUi();

        const clinic = clinicStore.clinic;
        const initialCoords = clinicStore.clinicCoords;
        const initialZoom = clinic.attributes?.mapzoom || 9;

        return {
            homeStore,
            clinicStore,
            uiStore,
            initialCoords,
            initialZoom
        };
    },

    watch: {
        "homeStore.coords": {
            handler(newCoords) {
                if (newCoords?.length) {
                    const mappedCoords = newCoords.map(coord => +coord);
                    this.coords = mappedCoords;

                    setTimeout(() => {
                        this.zoom = 13;
                        this.state = {
                            ...this.state,
                            center: mappedCoords,
                            zoom: 13
                        };
                    }, 2000);
                } else {
                    this.coords = this.initialCoords;
                    this.zoom = this.initialZoom;
                    setTimeout(() => {
                        this.state = {
                            ...this.state,
                            center: this.initialCoords,
                            zoom: +this.initialZoom
                        };
                    }, 2000);
                }
            },
            immediate: true
        },

        "uiStore.debugMode": {
            handler() {
                if (this.uiStore.debugMode) {
                    this.updateDebugBounds();
                }
                this.showMap = true;
            }
        }
    },

    async mounted() {
        if (window.ymaps) {
            await window.ymaps.ready();
            this.ymaps = window.ymaps;
        }

        this.coords = this.initialCoords;
        this.zoom = this.initialZoom;

        const region = this.clinicStore.clinicRegion;
        if (region) {
            await this.homeStore.fetchZonesAdd(region);
            this.listPolygon = this.homeStore.zonePolygonByRegion();

            if (this.uiStore.debugMode) {
                this.updateDebugBounds();
            }

            this.showMap = true;
        }
    },

    methods: {
        updateDebugBounds() {
            this.bounds = this.homeStore.boundedCompile(this.listPolygon);
            this.rectangleBounds = this.homeStore.getRectangleVertices(
                this.bounds[0],
                this.bounds[1]
            );

            this.listPolygon.push({
                title: "bounds",
                color: "#D3016E",
                coords: [[...this.rectangleBounds]]
            });
        },

        onBoundsChange(event) {
            // Добавить обработку изменения границ если необходимо
        },

        async onMapActionEnd(e) {
            const map = e.get('target');
            const center = map.getCenter();
            
            this.coords = center;
            this.homeStore.coords = center;
            
            await this.geocodeCoords(center);
        },

        async geocodeCoords(coords) {
            if (!this.ymaps) return;

            try {
                const result = await this.ymaps.geocode(coords);
                const firstGeoObject = result.geoObjects.get(0);
                
                if (firstGeoObject) {
                    const address = firstGeoObject.getAddressLine();
                    
                    this.homeStore.address = address;
                    
                    const addressComponents = {
                        country: firstGeoObject.getCountry(),
                        city: firstGeoObject.getLocalities()[0],
                        street: firstGeoObject.getThoroughfare(),
                        house: firstGeoObject.getPremiseNumber(),
                        fullAddress: address,
                        geoObject: firstGeoObject
                    };
                    
                    this.homeStore.updateAddressComponents(addressComponents);
                }
            } catch (error) {
                console.error('Ошибка геокодирования:', error);
            }
        }
    }
};
</script>

<style scoped>
#map,
.home :deep(.ymap-container) {
    width: 100%;
    height: 285px;
    position: relative;
}

/* Добавляем стили для центрального маркера */
.home :deep(.ymaps-2-1-79-image) {
    position: absolute;
    transform: translate(-50%, -50%);
}
</style>
