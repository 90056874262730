import {acceptHMRUpdate, defineStore} from "pinia";

export const usePatient = defineStore("patient", {
    state: () => ({
        patient: {
            age: null,
            name: null,
            phone: null,
            bday: null,
            reason: null,
            promo: null,
            comment: null,
            address: null,
            apartment: null,
            caddress: null,
            front: null,
            floor: null,
        },
        error: false,
    }),
    getters: {
        checkValidHome() {
            return this.checkValid.filter(field => field !== 'age');
        },
        checkValid() {
            const errorFields = [];
            const fieldsToCheck = ['name', 'phone', 'bday'];
            const invalidField = field => !this.patient[field] || this.patient[field].length < 1;

            fieldsToCheck.forEach(field => {
                if (invalidField(field)) errorFields.push(field);
            });

            if (this.patient.phone && this.patient.phone?.length !== 18) errorFields.push('phone');

            if (this.patient.bday && this.patient.bday?.length !== 10) errorFields.push('bday');

            const checkAgeValidity = () => {
                const today = new Date();
                const [day, month, year] = this.patient.bday.split('.');
                const birthDate = new Date(`${year}-${month}-${day}`);

                let age = today.getFullYear() - birthDate.getFullYear();

                if (today.getMonth() < birthDate.getMonth() ||
                    (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
                    age--;
                }
                return (age === this.patient.age || (age >= 18 && this.patient.age === 18));
            }

            if (this.patient.bday && !checkAgeValidity()) errorFields.push('age');

            return errorFields;
        }
    },
    actions: {
        setDefault() {
            this.patient.age = 5;
            this.patient.name = 'Фамилия Имя Отчество';
            this.patient.phone = '+7 (921) 409 06 81';
            this.patient.bday = '01.01.2019';
            this.patient.reason = 'Тест. Удалить.';
        }
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePatient, import.meta.hot));
}
