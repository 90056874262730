<template>
    <TransitionRoot as="template" :show="homeStore.open">
        <Dialog
            as="div"
            class="fixed inset-0 overflow-hidden dialog-app z-20"
            @close="homeStore.open = false"
        >
            <div class="absolute inset-0 overflow-hidden">
                <DialogOverlay class="absolute inset-0 bg-orange-50 bg-opacity-90" />

                <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-16">
                    <TransitionChild
                        as="template"
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enter-from="translate-x-full"
                        enter-to="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leave-from="translate-x-0"
                        leave-to="translate-x-full">
                        <div
                            class="pointer-events-auto flex flex-col gap-2 items-center relative w-screen md:w-[420px] bg-white pt-2">
                            <div class="px-4 flex justify-between items-center self-stretch relative w-full h-10 bg-transparent">
                                <div @click="homeStore.nav.currentStep = homeStore.nav.currentStep - 1;"
                                     class="w-[34px] h-10 flex justify-between items-center"
                                     :class="{'cursor-pointer': true}">
                                    <svg width="14" height="11"
                                        v-if="homeStore.nav.currentStep > 0 && homeStore.nav.currentStep != 4"
                                         viewBox="0 0 14 11"
                                         fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 5.5L13 5.5M1 5.5L5 1M1 5.5L5 10" stroke="#292B33" stroke-width="1.5"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <p class="tracking-[0.14em] uppercase text-center leading-6 text-sm text-[#292b33]">
                                    {{ title }}</p>
                                <div class="rounded-3xl p-1 flex gap-1 items-start relative bg-[#f8f7fa] cursor-pointer"
                                     @click="homeStore.open = false">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.75735 7.75735L16.2426 16.2426" stroke="#9E9BA3"
                                              stroke-width="1.5"
                                              stroke-linecap="round"></path>
                                        <path d="M16.2426 7.75735L7.75736 16.2426" stroke="#9E9BA3"
                                              stroke-width="1.5"
                                              stroke-linecap="round"></path>
                                    </svg>
                                </div>
                            </div>
                            <Home/>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import { useNav } from "~/store/app/nav";
import { useHome } from "~/store/home";

export default {
    setup() {
        const homeStore = useHome();
        const navStore = useNav();

        const handleBack = () => {
            if (homeStore.step > 0) {
                homeStore.step--;
            }
        };

        return {
            homeStore,
            title: "Вызов педиатра на дом",
            navStore,
            handleBack
        };
    },
    watch: {
        "homeStore.open": (val) => {
            if (val === false) {
                this.homeStore.clearHome();
            }
        }
    },
    methods: {
    },
    components: {
        XIcon,
        Dialog,
        DialogTitle,
        DialogOverlay,
        TransitionRoot,
        TransitionChild
    }
};
</script>
