<template>
    <div v-show="!loader" class="overflow-auto w-full h-full pt-0" id="wrap_top">
        <div id="top" class="pb-0" />
        <template v-if="true">
            <Transition>
                <HomeStep0 v-if="+homeStore.nav.currentStep === 0" />
            </Transition>
            <Transition>
                <HomeStep1 v-if="+homeStore.nav.currentStep === 1" />
            </Transition>
            <Transition>
                <HomeStep2 v-if="+homeStore.nav.currentStep === 2" />
            </Transition>
            <Transition>
                <HomeStep3 v-if="+homeStore.nav.currentStep === 3" />
            </Transition>
            <Transition>
                <HomeStep4 v-if="+homeStore.nav.currentStep === 4" />
            </Transition>
        </template>
        <template v-else>
            <Transition>
                <AppError />
            </Transition>
        </template>
    </div>

    <div v-show="loader" class="overflow-auto w-full h-full p-4 flex items-center justify-center">
        <AppLoader />
    </div>
</template>

<script>
import { useNav } from "~/store/app/nav";
import { useRegion } from "~/store/app/region";
import { useGroups } from "~/store/app/priceGroup";
import { useDocs } from "~/store/docs";
import { usePrices } from "~/store/price";
import { useSpecials } from "~/store/specials";
import { useUi } from "~/store/ui";
import { useHome } from "~/store/home";

export default {
    name: "HomeIndex",

    data() {
        return {
            loader: true
        };
    },

    setup() {
        const uiStore = useUi();
        const navStore = useNav();
        const regionStore = useRegion();
        const groupStore = useGroups();
        const docStore = useDocs();
        const priceStore = usePrices();
        const specialStore = useSpecials();
        const homeStore = useHome();

        const router = useRouter();

        // Отслеживание изменений шага навигации
        watch(() => navStore.step, (newStep, oldStep) => {
            navStore.error = false;
            if (newStep !== oldStep && newStep > 0) {
                router.push({ hash: `#${newStep}` });
            }
        });

        // Отслеживание изменений хэша в URL
        watch(() => router.currentRoute.value.hash, (newHash) => {
            navStore.error = false;
            const step = newHash.replace("#", "");
            if (!isNaN(step) && step !== navStore.step) {
                navStore.step = parseInt(step);
            }
        });

        regionStore.setRegion();

        return {
            homeStore,
            uiStore,
            navStore,
            regionStore,
            docStore,
            groupStore,
            priceStore,
            specialStore
        };
    },

    async mounted() {
        this.navStore.step = 0;
        this.navStore.maxStep = 0;
        this.loader = false;
    },

    unmounted() {
        if (!window.location.hash) return;

        const cleanUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
        window.history.replaceState({}, document.title, cleanUrl);
    }
};
</script>

<style scoped>
.v-enter-active {
    transition: opacity 0.6s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
