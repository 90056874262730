<template>
    <div v-if="homeStore.zone !== null && !Object.values(homeStore.zone).length">
        <div class="py-4 rounded-lg mt-5 bg-[#fdf8f7]">
            <p class="font-bold text-base">
                К сожалению, мы пока не сможем приехать по этому адресу;(
            </p>
            <p>
                Пожалуйста, оставьте заявку - так мы быстрее включим ваш адрес в зону выездов и пришлем вам оповещение
            </p>

            <div
                    class="w-full mt-2 flex items-center justify-center px-4 py-4 border border-pink-600 rounded-full shadow-sm text-sm uppercase font-medium text-pink-600 border-pink-600 bg-white hover:bg-pink-700 hover:text-white cursor-pointer"
                    @click="slotsStore.openOrder();"
            >
                Оставить заявку
            </div>
        </div>
    </div>
    <div
            v-else
            class="flex-shrink-0 px-2 mt-8"
    >
        <div class="flex flex-wrap items-center">
            <div class="mb-4">
                <div class="text-sm w-full hidden">
                    Укажите точный адрес: населенный пункт, улицу и дом
                </div>
            </div>

            <div
                    v-if="homeStore.zone"
                    class="inline-flex justify-center rounded-full border border-transparent py-3 px-3 w-full tracking-wider text-sm uppercase font-medium shadow-sm  bg-teal-100 cursor-pointer"
                    @click="goNext"
            >
                <div
                        v-if="homeStore?.zone?.cost"
                        class="flex justify-between whitespace-nowrap items-center w-full"
                >
                    <div class="px-4 flex-nowrap">оформить вызов</div>
                    <div
                            class="px-4 font-medium whitespace-nowrap border border-white bg-white text-black rounded-full py-2"
                    >
                        {{ numberWithSpaces(homeStore.zone.cost) }} р
                    </div>
                </div>
            </div>
            <div v-else
                 class="inline-flex justify-center rounded-full border border-transparent py-3 px-3 w-full tracking-wider text-sm uppercase font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 bg-gray-200 cursor-not-allowed">
                <div class="px-4">Продолжить</div>
            </div>

        </div>
    </div>
</template>

<script>
import {useHome} from "~/store/home";
import {useSlots} from "~/store/slots";
import {numberWithSpaces} from "~/libs/ui";
import {scrollTo} from "~/libs/ui";

export default {
    setup() {
        const homeStore = useHome();
        const slotsStore = useSlots();

        return {
            homeStore,
            slotsStore,
            numberWithSpaces,
            scrollTo,
        };
    },
    methods: {
        async goNext() {
            await this.slotsStore.homeCompile();
            this.scrollTo("address", "dialog-app", 50);
        },
    },
};
</script>
