<template>
    <div class="relative z-20" id="address">
        <div class="relative">
            <form @submit.prevent>
                <div class="relative">
                    <input ref="input" type="text" v-model="address" @blur="blurInput" @focus="focusInput"
                           @input="handleInput" @keyup.enter="handleEnter" placeholder="Улица, дом"
                           class="block w-full z-10 h-14 px-2 border rounded-md pr-10" style="padding-right: 5px" />
                </div>
            </form>
        </div>

        <ul v-if="suggestions.length > 0 || (address && focus)"
            class="rounded-md bg-white shadow-xs w-full border rounded-md border-gray-300 shadow-sm -mt-1 z-5"
            @mousedown="clickList = true" ref="list">
            <li v-if="suggestions.length === 0 && address && focus && !validAddress"
                class="px-2 py-3 cursor-default border-b border-gray-200">
                загрузкa..
            </li>
            <li v-for="(suggestion, num) in suggestions" :key="suggestion"
                class="px-2 py-3 cursor-pointer border-b border-gray-200" :ref="`list-${num}`"
                @click="setSelectedAddress(suggestion); scrollToEnd();">
                <div>{{ suggestion?.title?.text }}</div>
                <div class="text-sm opacity-50" v-if="suggestion?.subtitle?.text">{{ suggestion?.subtitle?.text }}</div>
            </li>
        </ul>
    </div>
</template>

<script>
import { useHome } from "~/store/home";
import { scrollTo } from "~/libs/ui";
import { useClinics } from "~/store/clinics";
import { debounce } from "~/libs/utils";

export default {
    data() {
        return {
            clickList: false,
            searchIcon: false,
            zone: null,
            address: "",
            suggestions: [],
            selectedIndex: -1,
            coordinates: "",
            validAddress: false,
            focus: false,
            debounceOff: false,
            touch: false
        };
    },
    setup() {
        const homeStore = useHome();
        // const slotsStore = useSlots();
        const clinicStore = useClinics();

        const clinic = clinicStore.clinic;

        const hasNumbers = (string) => {
            const regex = /\d/;
            return regex.test(string);
        };

        return {
            scrollTo,
            hasNumbers,
            homeStore,
            // slotsStore,
            clinic
        };
    },
    computed: {
        needHouse() {
            return this.touch && this.address.length > 0 && !this.hasNumbers(this.address);
        }
    },
    methods: {
        focusInput() {
            this.clickList = false;
            this.focus = true;
            this.scrollToEnd();
        },
        handleInput() {
            this.checkValidAddress();
            if (!this.checkValidAddress()) {
                this.homeStore.address = null;
                this.homeStore.zone = null;
                this.homeStore.coords = [];
            }
            // this.slotsStore.patient.address = this.address;
            this.debouncedSave();
        },
        async blurInput() {
            this.focus = false;
            if (this.clickList) return;
            this.suggestions = [];
            this.checkValidAddress();
            if (this.validAddress) {
                this.setSelectedAddress();
                this.updateAddressMap();
            }
        },
        handleEnter() {
            if (this.validAddress) {
                this.homeStore.address = this.address;
                this.homeStore.addressComponents = {
                    fullAddress: this.address,
                    isComplete: this.hasNumbers(this.address)
                };
                this.updateAddressMap();
                this.suggestions = [];
                this.focus = false;
            }
            this.$refs.input?.blur();
        },
        scrollToEnd() {
            setTimeout(() => {
                if (!this.$refs.input) return;
                this.$refs.input.scrollLeft = this.$refs.input.scrollWidth;
            }, 100);
        },
        checkValidAddress() {
            const str = this.address;
            const russianChars = /[а-яА-Я]/g;
            const nums = /[0-9]/g;
            const matches1 = str.match(russianChars);
            const matches2 = str.match(nums);
            this.validAddress =
                matches1 !== null &&
                matches2 !== null &&
                matches1.length >= 4 &&
                matches1.length >= 1;
            return this.validAddress;
        },
        async getAddressSuggestions() {
            try {
                const boundedRegion = this.homeStore.boundedCompile();
                const req = this.address;

                const baseUrl = "https://suggest-maps.yandex.ru/v1/suggest";
                const apiKey = "204726f8-2709-4840-971f-6a2287a88ce1";
                const bbox = `${boundedRegion[0][1]},${boundedRegion[0][0]}~${boundedRegion[1][1]},${boundedRegion[1][0]}`;
                const types = "street,house";

                const url = `${baseUrl}?apikey=${apiKey}&text=${req}&bbox=${bbox}&strict_bounds=1&types=${types}`;

                fetch(url)
                    .then((response) => {
                        if (response.status !== 200) return null;
                        return response.json();
                    })
                    .then((suggestions) => {
                        this.suggestions = suggestions?.results ? suggestions?.results : [];
                    });
            } catch (error) {
                console.error(error);
            }
        },
        setSelectedAddress(suggestion) {
            this.touch = true;
            if (!suggestion) {
                this.homeStore.homeAddress = this.address;
                // this.slotsStore.patient.address = this.address;

                if (!this.hasNumbers(this.address)) {
                    this.$refs.input.focus();
                    this.getAddressSuggestions();
                } else {
                    this.$refs.input.blur();
                    this.updateAddressMap();
                    this.suggestions = [];
                }
                return;
            }

            if (suggestion.tags?.includes("house")) this.debounceOff = true;

            this.homeStore.homeAddress = suggestion?.title?.text
                ? suggestion?.subtitle?.text + ", " + suggestion?.title?.text + (this.hasNumbers(suggestion?.title?.text) ? "" : ", ")
                : this.address;
            this.address = this.homeStore.homeAddress;
            // this.slotsStore.patient.address = this.address;

            if (!this.hasNumbers(this.address)) {
                this.$refs.input.focus();
            } else {
                this.$refs.input.blur();
                this.updateAddressMap();
                this.suggestions = [];
            }
            this.checkValidAddress();
        },
        async updateAddressMap() {
            await this.getCoordinates();
            await this.findPoligon();
            this.searchIcon = false;
        },
        async getCoordinates() {
            const boundedRegion = this.homeStore.boundedCompile();
            const bbox = `${boundedRegion[0][1]},${boundedRegion[0][0]}~${boundedRegion[1][1]},${boundedRegion[1][0]}`;

            async function geocode(address) {
                const url = "https://geocode-maps.yandex.ru/1.x/";
                const params = new URLSearchParams();
                params.append("apikey", "4f9fce41-93d6-44f6-be5a-a8145eb23ca7");
                params.append("format", "json");
                params.append("geocode", address);
                params.append("bbox", bbox);

                const response = await fetch(`${url}?${params}`);
                const data = await response.json();
                return data["response"]["GeoObjectCollection"]["featureMember"][0][
                    "GeoObject"
                    ]["Point"]["pos"];
            }

            this.coordinates = await geocode(this.address);
            this.homeStore.coords = this.coordinates.split(" ").reverse().map((coord) => +coord);
            this.zoom = 15;

            this.checkValidAddress();
        },
        findPoligon() {
            const [latitude, longitude] = this.coordinates.split(" ");

            this.homeStore.zone = {};

            for (let zone of this.homeStore.listZones) {
                if (
                    this.homeStore.isCoordinateInsidePolygon(
                        [latitude, longitude],
                        zone.attributes.coordinates
                    )
                ) {
                    this.homeStore.zone = zone.attributes;
                    break;
                }
            }
        },
        clearHome() {
            this.touch = false;
            this.address = "";
            this.suggestions = [];
            this.$refs.input.focus();
            // this.slotsStore.target = null;
            // this.slotsStore.mapApprove = null;
            // this.slotsStore.patient.address = "";
            // this.slotsStore.date = null;
            // this.slotsStore.time = null;
            // this.slotsStore.calendar = [];
            this.homeStore.address = null;
            this.homeStore.zone = null;
            this.homeStore.coords = [];
        },
        saveAddressAfterPause() {
            if (this.validAddress) {
                this.homeStore.address = this.address;
                this.homeStore.addressComponents = {
                    fullAddress: this.address,
                    isComplete: this.hasNumbers(this.address)
                };
                this.updateAddressMap();
            }
        }
    },
    watch: {
        address(...args) {
            if (!this.debounceOff) {
                this.debouncedFetch(...args);
            }
            this.debounceOff = false;
        },
        "homeStore.addressComponents": {
            handler(newComponents) {
                if (newComponents?.fullAddress) {
                    this.address = newComponents.fullAddress;
                    if (!this.hasNumbers(newComponents.fullAddress)) {
                        this.focusInput();
                        this.getAddressSuggestions();
                    } else {
                        this.suggestions = [];
                        this.$refs.input?.blur();
                        this.focus = false;
                        this.updateAddressMap();
                    }
                }
            }
        }
    },
    created() {
        this.debouncedFetch = debounce(() => {
            this.getAddressSuggestions();
        }, 1000);

        this.debouncedSave = debounce(() => {
            this.saveAddressAfterPause();
        }, 7000);
    },
    mounted() {
        this.focus = false;
        if (this.homeStore.address) {
            this.address = this.homeStore.address;
            this.checkValidAddress();
        }
    }
};
</script>
