<template>
    <div class="w-full overflow-hidden h-10 bg-[#bbefec] wrap_snail" :class="{'snail_go': go}">
        <template v-if="appStore.debug">
            <div class="absolute mt-1 right-1 w-6 h-6 text-center border rounded-2xl bg-gray-50 cursor-pointer"
                 @click="navStore.step--">{{ navStore.step }}
            </div>
        </template>
        <div class="snail" @click="clickAdd">
            <div class="shell"></div>
            <div class="slug"></div>
        </div>
        <div class="slime"></div>
    </div>
</template>

<script>
import {useApp} from "~/store/app/app";
import {useNav} from "~/store/app/nav";
import {scrollTo} from "~/libs/ui";
import { useHome } from "~/store/home";
import { useUi } from "~/store/ui";

export default {
    setup() {
        const appStore = useApp();
        const navStore = useNav();
        const uiStore = useUi();

        return {
            uiStore,
            appStore,
            navStore,
            timeoutId: null,
            remainingTime: 0
        }
    },
    data() {
        return {
            go: false,
            step: 2500,
            lastSnailGoTime: 0,
            countClick: 0,
        }
    },
    watch: {
        'navStore.step': function (val) {
            scrollTo('top', 'wrap_top');
            if (val > this.navStore.maxStep) {
                this.navStore.maxStep = val;
                let time = this.step;
                if (val === 7)
                    time = 9000;

                if (this.go) {
                    clearTimeout(this.timeoutId);
                    let elapsedTime = Date.now() - this.lastSnailGoTime;
                    let remainingTime = Math.max(0, this.remainingTime - elapsedTime);
                    this.remainingTime = remainingTime + time;
                    this.snailGo(this.remainingTime);
                } else {
                    this.snailGo(time);
                }
            }
        }
    },
    methods: {
        clickAdd() {
            this.countClick++;
            if (this.countClick === 3) {
                this.appStore.setDebug(true);
                this.uiStore.debugMode = !this.uiStore.debugMode;
            }
        },
        snailGo(time) {
            // console.info('Snail go ' + time)
            this.go = true;
            this.lastSnailGoTime = Date.now();
            this.timeoutId = setTimeout(() => {
                this.go = false;
                // console.info('Snail stop ' + time)
                this.remainingTime = 0;
            }, time)
        }
    },
    mounted() {
        this.snailGo(3500);
    },
    beforeUnmount() {
        clearTimeout(this.timeoutId);
    }
}
</script>


<style>
@media screen and (max-device-width: 480px) {
    @supports (-webkit-touch-callout: none) {
        .wrap_snail {
            padding-bottom: 7px;
        }
    }
}


.wrap_snail {
    position: relative;
}

.wrap_snail.snail_go {
    .snail, .slime {
        animation-play-state: running !important;
    }
}

.snail {
    scale: 0.4;
    height: 77px;
    width: 130px;
    position: absolute;
    z-index: 1;
    top: -19px;
    animation: 25s snailCrawl linear infinite;
    animation-play-state: paused;

    &:hover {
        /*animation-play-state: running;*/
    }
}

.shell {
    height: 30px;
    width: 55px;
    border-radius: 100%;
    border: 25px solid #fff;
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 15px;
    overflow: visible;
    transform: rotate(-10deg);
    animation: 1.25s slugSquish ease-in-out infinite;
}

.slug {
    width: 170px;
    height: 30px;
    position: absolute;
    bottom: 0;
    right: 0;
    background: white;
    border-radius: 0 30px 5px 0;
    animation: 1.25s slugSquish ease-in-out infinite;
}

.slug:before {
    content: ".";
    font-size: 50px;
    line-height: 0px;
    color: transparent;
    display: block;
    position: absolute;
    height: 30px;
    width: 2px;
    background: white;
    right: 10px;
    top: -25px;
    transform: rotate(30deg);
    box-shadow: 6px 5px 0 white;
}

.slug:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    left: -100px;
    height: 0;
    width: 0;
    border-left: 100px solid transparent;
    border-bottom: 30px solid white;
}

.slime {
    height: 3px;
    width: 35%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
    position: absolute;
    z-index: 0;
    top: 32px;
    opacity: 0.2;
    left: -10%;
    animation: 25s slimeSpread linear infinite;
    border-radius: 3px;
    animation-play-state: paused;
}

@keyframes snailCrawl {
    0% {
        left: -120px;
    }
    100% {
        left: calc(100% + 120px);
    }
}

@keyframes slugSquish {
    0% {
        width: 50px;
        right: -5px;
    }
    50% {
        width: 40px;
        right: 0px;
    }
    100% {
        width: 50px;
        right: -5px;
    }
}

@keyframes slimeSpread {
    0% {
        left: -50%;
        opacity: 0.8;
    }
    95% {
        opacity: 0.8;
    }
    100% {
        left: calc(50% + 240px);
        opacity: 0;
    }
}
</style>
